const BASE = 10;
const SECOND = 1000;

const reloadSrc = (element, srcAttribute) => {
  const separator = "#re";
  const src = element.getAttribute(srcAttribute).split(separator)[0];
  fetch(src, {
    cache: "default",
  }).then((response) => {
    const contentLength = parseInt(response.headers.get("Content-Length"));
    if (response.ok && contentLength > 0) {
      element.setAttribute(srcAttribute, src + separator + Math.random());
    }
  });
};

export default function (
  dataSrcAttribute = "src",
  refreshIntervalAttribute = "data-refresh"
) {
  Array.from(
    document.querySelectorAll(
      `[${dataSrcAttribute}][${refreshIntervalAttribute}]`
    )
  ).forEach((element) => {
    const dataRefresh = parseInt(
      element.getAttribute(refreshIntervalAttribute),
      BASE
    );
    const dataRefreshAsMs = dataRefresh * SECOND;
    setInterval(reloadSrc, dataRefreshAsMs, element, dataSrcAttribute);
  });
}
