import axios from "axios";

function create_news_item({ url, title, timestamp, content } = {}) {
  return `<section class="news-item">  
        <div class="news-item-date">${timestamp}</div>
        <div class="news-item-content">
            <a href="${url}">
                <h4>${title}</h4>
            </a>
            ${content}
        </div>
        <div class="news-item-image"></div>
    </section>`;
}

export default async function (selector) {
  const placeholder = document.querySelector(selector);
  const urls = [...placeholder.querySelectorAll("a")].map((elem) => {
    const url = elem.href;
    elem.parentNode.removeChild(elem);

    return url;
  });

  const parser = new DOMParser();

  const requests = urls.map((url) => axios.get(url));
  const responses = await axios.all(requests);

  const news = [];
  for (const [index, resp] of responses.entries()) {
    const doc = parser.parseFromString(resp.data, "text/html");

    const main = doc.querySelector("main");
    const title = main.querySelector("h1").innerHTML;
    const timestamp = main.querySelector("time").innerHTML;
    const content = main.querySelector("section").innerHTML;
    const url = urls[index];

    news.push({
      title,
      timestamp,
      content,
      url,
    });
  }

  news.sort((a, b) => (a.timestamp > b.timestamp ? -1 : 1));

  for (const item of news) {
    placeholder.insertAdjacentHTML("beforeend", create_news_item(item));
  }
}
