import stickyNav from "./stickyNav";
import refreshElemSrc from "./refreshElemSrc";
import news from "./news";

stickyNav({
  navElemSelector: ".wrapper > nav",
  headerElemSelector: ".wrapper > header",
  stickyClass: "sticky",
});

refreshElemSrc("data");
refreshElemSrc("src");

const nonStandardPlaybackRates = document.querySelectorAll(
  "video[data-playback-rate]"
);
[...nonStandardPlaybackRates].forEach((video) => {
  video.playbackRate = video.dataset.playbackRate;
});

window.load_news = news;
