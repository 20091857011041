import inView from "in-view";

let _navElemSelector, _headerElemSelector, _stickyClass;

function stickyNav() {
  const headerElem = document.querySelector(_headerElemSelector);
  const navElem = document.querySelector(_navElemSelector);

  if (!inView.is(headerElem)) {
    navElem.classList.add(_stickyClass);
  }

  inView(_headerElemSelector)
    .on("enter", () => navElem.classList.remove(_stickyClass))
    .on("exit", () => navElem.classList.add(_stickyClass));
}

function ready() {
  if (document.querySelector(_navElemSelector)) {
    stickyNav();
    return;
  }

  window.requestAnimationFrame(ready);
}

export default function ({
  navElemSelector = "nav",
  headerElemSelector = "header",
  stickyClass = "sticky",
} = {}) {
  [_navElemSelector, _headerElemSelector, _stickyClass] = [
    navElemSelector,
    headerElemSelector,
    stickyClass,
  ];

  window.requestAnimationFrame(ready);
}
